import { StaticImage } from "gatsby-plugin-image"
import { Paragraph } from "grommet"
import { Anchor } from "grommet/components/Anchor"
import { Box } from "grommet/components/Box"
import React from "react"
import Layout from "../components/Layout"

const IndexPage = () => {
  return (
    <Layout>
      <Box width={"large"}>
        <Paragraph>
          On the mission to empower enterprises with innovative data
          infrastructure solutions that unlock the full potential of their data.
        </Paragraph>
        <Paragraph>
          <Anchor href="mailto:hello@orchestack.com">
            hello@orchestack.com
          </Anchor>
        </Paragraph>
        <div>
          <StaticImage width="190" src="../images/logo.png" />
        </div>
      </Box>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Orchestack</title>
