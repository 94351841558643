import { Link } from "gatsby"
import { Anchor } from "grommet/components/Anchor"
import { Box } from "grommet/components/Box"
import { StaticImage } from "gatsby-plugin-image"
import { Footer } from "grommet/components/Footer"
import { Grommet } from "grommet/components/Grommet"
import { Header } from "grommet/components/Header"
import { Heading } from "grommet/components/Heading"
import { Main } from "grommet/components/Main"
import React from "react"

export default function Layout({ children }) {
  return (
    <Grommet
      full="min"
      theme={{
        global: {
          font: {
            family: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif`,
          },
        },
        anchor: {
          color: "black",
          textDecoration: "underline",
          hover: {
            textDecoration: "none",
          },
        },
      }}
    >
      <Box>
        <Box alignSelf={"center"} width={"xlarge"} pad={"xlarge"}>
          <Header direction="row" justify="between" gap="medium">
            <Heading level={1}>
              <Anchor
                to="/"
                as={Link}
                style={{ textDecoration: "none", fontWeight: "bold" }}
              >
                Orchestack
              </Anchor>
            </Heading>
          </Header>
          <Main>{children}</Main>
          <Footer></Footer>
        </Box>
      </Box>
    </Grommet>
  )
}
